.App {
  text-align: center;
}

.App-logo {
  height: 10;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2C2C54;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.body {
  background-color: #29272f;
  padding-top: 60px;
}

.container {
  background-color: #393947; /* Your desired background color */
  padding: 40px; /* Adjust padding as needed */
  border-radius: 10px;
  width: 500px;
  display: flex;
  align-items: center;
  margin: 3rem 0;
  flex-direction: column; 
}

table {
  border-spacing: 0;
  border: 1px solid 6c63ff;
}

tr:last-child > td {
  border-bottom: 0;
}

th,
td {
  color: #c70c1b;
  font-weight: bold;
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
th:last-child ,
td:last-child {
  border-right: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* her starter mitt */

h1, h2 {
  color: #c70c1b;
}

h3 {
  color:#c70c1b;
}

h4 {
  color:#c70c1b;
}

.signature {
  font-family: 'Courier New', Courier, monospace;
  font-size: 40px;
  color:#AAABB8;
  margin-top: 10vh;
}

.urls {
  position: fixed; /* Keeps the URLs fixed on the page */
  right: 0; /* Aligns the container to the right side */
  top: 50%; /* Vertically centers the container */
  transform: translateY(-50%); /* Adjusts position to be perfectly centered */
  display: flex;
  flex-direction: column; /* Stacks the links vertically */
  align-items: center; /* Centers the links horizontally within the container */
  padding-right: 10px; /* Adds some space between the links and the vertical line */
  font-weight: bold;
  z-index: 9999; /* High z-index to ensure it stays on top of other elements */
}

.urls::after {
  content: ''; /* Creates a pseudo-element for the vertical line */
  position: absolute;
  right: 0; /* Aligns the line to the right edge of the container */
  top: 0;
  bottom: 0;
  width: 2px; /* Adjusts the thickness of the vertical line */
  background-color: #333; /* Sets the color of the vertical line */
}

.urls a {
  margin: 10px 0; /* Adds space between each link */
  color: white; /* Sets the color of the links */
  text-decoration: none; /* Removes underline from links */
  transition: color 0.3s ease; /* Smooth color transition on hover */
  font-size: 20px;
}

.urls a:hover {
  color: #ff0000; /* Changes link color on hover */
}


.navigationBar {
  overflow: hidden;
  position: fixed;
  top: 0; 
  width: 100%; /* Adjust width as needed */
  font-weight: bold;
  display: flex;
  flex-direction: row; /* Stack items vertically */
  justify-content: flex-end; /* Align items to start at the top */
  background-color: #29272f;
  z-index: 1000; /* Ensure it's above other elements */
  padding: 10px 20px;
}

.navigationBar a {
  font-family: Courier New;
  font-size: 20px;
  font-weight: bold;
  float: left;
  display: block;
  color: #AAABB8;
  text-align: center;
  padding: 14px 20px;
  margin: 0px;
  text-decoration: none;
  background: #29272f;
}


/* Change color on hover */
.navigationBar a:hover {
  background-color: #c70c1b;
  color: black;
  line-height: 100%;
}

/* Active/current link */
.navigationBar a.active {
  background-color: #6c63ff;
  color: white;
}

/* Default link styles */
a {
  color: #e7e7ec; /* Link color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

/* Hover effect */
a:hover {
  color: #e7e7ec  ; /* Change color on hover */
  border-bottom: 1px solid black; /* Thin line on the right side */
}

/* Active link styles (when clicked) */
a:active {
  color: #0b0721; /* Active link color */
}

/******************************
ABOUT ME - section
******************************/
.aboutme-container {
  display: flex;
  align-items: center; /* Align items at the top */
  justify-content: center;
  padding: 20px;
  background-color: #29272f; /* Dark background to contrast with the text */
  color: white;
  text-align: left;
}

.aboutme-description {
  max-width: 30rem;
  padding-right: 2rem;
}

h3 {
  color: red; /* Set h3 text to red */
  margin: 0;
  line-height: 1.4; /* Adjust line height for better spacing */
}

.aboutme-header {
  font-size: 2.5em; /* Slightly smaller font size for balance */
  color: white;
  margin: 5px 0; /* Adjust margin for spacing */
  line-height: 1.2; /* Adjust line height */
}

.aboutme-textbox {
  margin-top: 20px;
  background-color: #29272f;
  border-radius: 8px;
  color: white;
  font-size: 1.1em;
  line-height: 1.5;
}

.aboutme-picture {
  flex: 0 0 0; /* Adjust the width of the picture section */
  text-align: center;
}

.profilepicture {
  width: 100%;
  border-radius: 10%; /* Rounded corners */
  object-fit: cover;
}

.urls {
  display: flex;
}

/* Media query for smaller screens (adjust the max-width as needed) */
@media screen and (max-width: 600px) {
  
  .navigationBar {
    height: auto;
    top: 0px;
    background-color: #29272f;;
    position: fixed;
    width: 100%;
    z-index: 1000;
    margin: 0;
    padding: 0;
  }

  .signature {
    margin-top: 12vh;
  }

  .row {   
    flex-direction: column;
  }

}

.phone {
  color: grey;
}

.phone:hover {
  color: #e7e7ec;
}

.projectLink {
  color: gray;
  background-color: #393947;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 0px;
  &:hover {
    background-color: #4c4c5b; /* Darker purple on hover */
    color: #971e28; /* White color on hover */
  }
}

.search {
  width: 200px; /* Set your desired width */
  margin: 10px; /* Add some margin for spacing */
  color: #4c4c5b; /* Grey text color */
}



/* Responsive layout - when the screen is less than 400px wide, 
make the navigation links stack on top of each other instead of next to each other */
@media screen and (max-width: 480px) {

  .urls {
    display: none;
  }
  
  .main {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .main > * {
    margin: 10px 0; /* Adjust margin for smaller screens */
    position: initial; /* Reset position for smaller screens */
  }

  .row {   
    flex-direction: column;
  }

  .jobDescription {
    width: 100%;
  }

  .experienceItem {
    width: 100%;
  }

  .container {
    width: 100%;
  }

  .textbox {
    width: 100%; /* Full width for smaller screens */
    padding: 0; /* Adjust as needed */
    margin: 0; /* Adjust as needed */
  }

  .displayWindows {
    align-items: center;
  }

  .aboutme-container {
    flex-direction: column; /* Stack elements vertically */
    padding-left: 20px; /* Remove or adjust the left padding for better alignment */
    gap: 10rem;
  }

  .aboutme-content {
    margin-right: 0; /* Remove right margin when stacked */
    margin-bottom: 20px; /* Add some space below content */
  }

  .aboutme-picture {
    width: 100%; /* Ensure picture takes full width */
    margin-bottom: 20px; /* Add some space below picture */
  }

  .profilepicture {
    width: 100%; /* Make sure the profile picture adapts to the container */
    height: auto; /* Maintain aspect ratio */
  }
}

/* Responsive layout - when the screen is less than 700px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 700px) {
  .aboutme-container {
    flex-direction: column; /* Stack elements vertically */
    padding-left: 20px; /* Remove or adjust the left padding for better alignment */
  }

  .aboutme-content {
    margin-right: 0; /* Remove right margin when stacked */
    margin-bottom: 20px; /* Add some space below content */
  }

  .aboutme-picture {
    width: 100%; /* Ensure picture takes full width */
    margin-bottom: 0px; /* Add some space below picture */
  }

  .profilepicture {
    width: 100%; /* Make sure the profile picture adapts to the container */
    /*height: 50px; /* Maintain aspect ratio */
  }
}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  background-color: #29272f;
}

* {
  box-sizing: border-box;
}

/* Column container */
.row {  
  /* display: -ms-flexbox; IE10 */
  display: flex;
  /* -ms-flex-wrap: wrap; IE10 */
  /* flex-wrap: wrap; */
  /* flex: 1; */
}

/* Create two unequal columns that sits next to each other */
/* Sidebar/left column */
.side {
  flex:0 0 30%; /* IE10 */
  background-color: #ECECEC;
  padding: 20px;
  border-right: 1px solid #ddd; /* Thin line on the right side */
}

/* Main column */
.main {   
  flex: 1; 
  background-color: #971e28;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
}

/* Center the content inside .main */
.main > * {
  margin: 100px;
  position: right;
}

.displayWindows {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subWindow {
  display: inline-block;
  padding: 8px 16px;
  margin: 10px;
  background-color: #7d1414; /* Purple color */
  color: #333; /* Grey color */
  font-size: 2.5em; /* Adjust the font size as needed */
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;

  /* Optional: Add hover effect */
  &:hover {
    background-color: #4c4c5b; /* Darker purple on hover */
    color: #fff; /* White color on hover */
  }
}

.codeImg {
  height: 350px;
  width: 280px;
}


.profilepicture {
  width: 350px; /* Set your desired width */
  height: 400px; /* Set your desired height */
  border-radius: 15%; /* This will create a circular shape (rounded corners) */
  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  display: block;
  margin: auto; /* Center the image horizontally */
  /* position: relative; */
}

.footer {
  padding: 5px;
  text-align: center;
  background: #29272f;
  color: #4c4c5b;
  bottom: 100%;
  width: 100%;
  font-size: small;
  opacity: 50%;
}

.contactme {
  bottom: 0;
  text-align: center;
}

.textbox {
  width: 100%; /* Set your desired width */
  padding: 10px; /* Set your desired padding */
  border-radius: 15px; /* Rounded corners */
  outline: none; /* Remove default input focus outline */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  font-size: 16px; /* Set your desired font size */
  position: relative;
  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
  display: block;
  margin: auto; /* Center the text horizontally */
  background-color: #29272f;
}

/* Optional: Add hover and focus styles for a subtle effect */
.textbox:hover,
.textbox:focus {
  border-color: #4c4c5b; /* Border color on hover/focus */
}

.course {
  color:#971e28;
}

.experienceItem {
  background-color: #393947;
  color: #393947;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 5px;
  width: 50%;

  &:hover {
    background-color: #4c4c5b; /* Darker purple on hover */
    color: #fff; /* White color on hover */
  }
}

.companyName {
  font-size: 1.5em;
  font-weight: bold;
  color: #971e28; /* Purple color */
  margin-bottom: 10px;
  margin-top: 10px;
}

.underheadline {
  font-size: 1em;
  font-weight: bold;
  color: #971e28; /* Purple color */
  margin: 0;
}

.dateRange {
  font-size: 1.2em;
  color: #971e28; /* Purple color */
}

.jobDescription {
  margin-top: 10px;
  color: white; /* Black color for job description */
  width: 60%;
}